import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const Introduction = ({ intro }) => {
    return (
        <section className="common-wrapper overflow-hidden max-height-600-px">
            {   intro.illustration && intro.illustration.fluid &&
                <Img
                    className="bg-white -mt-150 mt-0-md max-width-100-per"
                    fluid={intro.illustration ? intro.illustration.fluid : ''}
                    alt={intro.illustration ? intro.illustration.description : ''}
                />
            }
        </section>
    )
}

export default Introduction