import React from "react"
import { Link } from "gatsby"

const Services = ({ services }) => {
    return (
        <section className="common-wrapper py-20 py-10-md relative">
            <ul className="flex flex-col-md text-blue-dark justify-evenly dafinity-services -mt-200 mt-0-md">
                {services.map(service => {
                    return (
                        <li className="w-33-per w-full-md bg-white pb-10 px-10 px-5-md mx-10 mx-0-md shadow-1 shadow-0-md br-2" key={service.id}>
                            <div className="h-35 bc-blue-dark bs-solid bt-w-0 bl-w-0 br-w-0 bb-w-3 relative pt-10 mb-10">
                                <h2 className="mt-0 pr-0-md f2 font-bold lh-4 text-align-center text-align-left-md absolute transform-center w-full">{service.title}</h2>
                            </div>
                            <p className="lh-4 f4 font-normal">{service.description.description}</p>
                            <Link className="underline font-normal f45" to={service.link.slug || "/"}>En savoir plus</Link>
                        </li>
                    )
                })}
            </ul>

        </section>
    )
}

export default Services