import React from "react"
import Img from "gatsby-image/withIEPolyfill"
// import ArrowRight from "../../assets/ui/svg/arrow-right.svg"

const References = ({ title, references }) => {
    return (
        <section className="common-wrapper overflow-hidden bg-blue-dark px-4 py-20">
            <header className="flex justify-center items-center pb-30 px-40">
                <h4 className="f1 text-white font-bold m-0">{title}</h4>
                {/* <ArrowRight className="cursor-pointer" /> */}
            </header>
            <ul className="flex justify-evenly overflow-x-auto" style={{ cursor: 'ew-resize' }}>
                {references.map(reference => {
                    return (
                        <li key={reference.id} className="flex-col w-33-per min-width-250 bg-white py-4 px-4 mx-4 br-2 text-blue-dark shadow-1">
                            <header className="flex flex-col items-center justify-center py-2">
                                <p className="font-bold f4 text-blue-dark mt-0 mb-2">{reference.name}</p>
                                <p className="font-normal f6 mt-1 mb-2">{reference.mission}</p>
                                <div className="w-full h-40 flex justify-center items-center p-5 overflow-hidden">
                                    {reference.logo && reference.logo.fluid &&
                                        <Img
                                            className="w-full"
                                            fluid={reference.logo.fluid}
                                            alt={reference.logo.description}
                                        />
                                    }
                                </div>
                            </header>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

export default References