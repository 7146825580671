import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/index"
import Seo from "../components/seo"

//Page content
import Services from "../components/services"
import Introduction from "../components/introduction"
import Brochure from "../components/brochure"
import References from "../components/references"

const RootIndex = (props) => {

  const data = useStaticQuery(graphql`
  query MyQuery {
    contentfulPage(slug: {eq: "/"}) {
      title
      description {
        description
      }
      keywords
      pageContent {
        ... on ContentfulClientReferences {
          __typename
          id
          title
          clientReference {
            id
            name
            field
            mission
            logo {
              fluid(maxWidth: 150) {
                ...GatsbyContentfulFluid_noBase64
              }
              description
              title
            }
          }
        }
        ... on ContentfulCommercialBrochure {
          __typename
          id
          commercialBrochureLink {
            contentful_id
            file {
              url
            }
          }
          commercialTagline
        }
        ... on ContentfulHeroIntroduction {
          __typename
          id
          illustration {
            title
            description
            fluid(maxWidth: 1920, quality:80) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
        ... on ContentfulServices {
          __typename
          name
          servicesList {
            id
            title
            description {
              description
            }
            link {
              slug
            }
          }
        }
      }
    }
  }
  `)

  const services = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulServices").servicesList;
  const clientReferences = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulClientReferences");
  const commercialBrochure = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulCommercialBrochure");
  const introduction = data.contentfulPage.pageContent.find(content => content.__typename === "ContentfulHeroIntroduction");

  return (
    <Layout>
      <Seo
        title={data.contentfulPage.title}
        description={data.contentfulPage.description.description}
        keywords={data.contentfulPage.keywords} />
      <Introduction intro={introduction} />
      <Services services={services} />
      <Brochure brochure={commercialBrochure} />
      <References title={clientReferences.title} references={clientReferences.clientReference} />
    </Layout>
  )
}

export default RootIndex